import { lazy } from 'react'
const Mine = lazy(() => import(/* webpackChunkName: "Mine" */ '../../pages/Mine'))
const BiDataPage = lazy(() => import(/* webpackChunkName: "BiDataPage" */ '../../pages/BiDataPage'))

const RoutePage = [
  {
    name: '中能源BI数据',
    path: '/bi',
    auth: true,
    navBar: true,
    element: BiDataPage
  },
  {
    name: '安心加',
    path: '/activity/label',
    auth: true,
    tabBar: true,
    element: Mine
  }
] as RouteData[]

export default RoutePage
