import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
// import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
// import { SellerDetail } from './api/mall/seller'
// import { sellerDetailState, userState } from './recoil/seller'
// import { permissionState } from './recoil/auth'
// import permission from './libs/permission'
// import { HasPermission } from './libs/composables'
// import Storage from './libs/storage'
// import { StorageMapUser } from './libs/storage.map'
import RoutePage from './contants/routes/page'
import MainLayout from './components/Layouts/MainLayout'
import './assets/styles/common.scss'
import './assets/styles/adm.custom.scss'
import './App.css'

function App() {
    console.log(process.env.NODE_ENV);
//   const setSellerDetail = useSetRecoilState(sellerDetailState)
//   const [permissionList, setPermissionList] = useRecoilState(permissionState)
//   const userValue = useRecoilValue(userState)

  useEffect(() => {
    // const sid = Storage.Local.get(StorageMapUser.APP_STATE_SELLER_ID_LOCAL)
    // sid && SellerDetail(sid).then(data => {
    //   setSellerDetail(data)
    //   permission(data.id).then(data => {
    //     setPermissionList(data)
    //   })
    // })
  }, [])

  const RoutesRender = ({ route } : { route: RouteData }) => {
    document.title = route.name
    // if (route.auth) { // 需要登录
    //   if (!userValue.token) { // （需要登录） && 未登录
    //     return <Navigate to={ '/login' } replace />
    //   } else { // （需要登录） && 已登录
    //     if (permissionList.length) { // （需要登录 && 已登录） && 已加载权限列表
    //       if (!route.code) { // （需要登录 && 已登录 && 已加载权限列表） && 不需要权限
    //         return <route.element />
    //       } else if (HasPermission(route.code)) { // （需要登录 && 已登录 && 已加载权限列表） && 需要权限 && 有权限
    //         return <route.element />
    //       } else { // （需要登录 && 已登录 && 已加载权限列表） && 需要权限 && 无权限
    //         return <Navigate to={ '/403' } replace />
    //       }
    //     } else { // （需要登录 && 已登录） && 未加载权限列表
    //       if (!route.code) { // （需要登录 && 已登录 && 已加载权限列表） && 不需要权限
    //         return <route.element />
    //       } else { // （需要登录 && 已登录 && 已加载权限列表） && 需要权限
    //         return null
    //       }
    //     }
    //   }
    // } else { // 不需要登录
      return <route.element />
    // }
  }

  return (
    <>
      <Routes>
        {
          RoutePage.map(route => {
            return (
              <Route path={ route.path } key={ route.path } element={
                <RoutesRender route={ route } />
            //     <MainLayout tabBar={ route.tabBar } navBar={ route.navBar }>
                
            //   </MainLayout>
               } 
              />
            )
          })
        }
      </Routes>
    </>
  )
}

export default App
